import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import { Card } from "react-bootstrap";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";

const FilmFestivals = ({ selected }) => {
    const status = JSON.parse(localStorage.getItem("status"));
    const filmFestivals = [
        {
            "name": "7 moments",
            "festivalDetails": "International festival",
            "country": "Russia (Pavlovsky Posad)",
            "contactDetails": "kinofest17.tv",
            "time": "Event Dates: 26 April — 30 May 2023"
        },
        {
            "name": "1905 International Human Rights Film Festival",
            "festivalDetails": "International Human Rights Film Festival",
            "country": "Hong Kong",
            "contactDetails": "1905hhrf.net",
            "time": "Event Dates: 17 — 24 May 2019"
        },
        {
            "name": "20minmax",
            "festivalDetails": "International short film festival",
            "country": "Germany (Ingolstadt)",
            "contactDetails": "20minmax.com",
            "time": "Event Dates: 3 — 12 November 2023"
        },
        {
            "name": "24 fps Film Festival",
            "festivalDetails": "International short film festival",
            "country": "(USA) Abilene",
            "contactDetails": "24fpsfest.com",
            "time": "Event Dates: 1 — 2 November 2024"
        },
        {
            "name": "25 FPS",
            "festivalDetails": "International festival for independent and non-commercial films",
            "country": "Croatia (Zagreb)",
            "contactDetails": "25fps.hr",
            "time": "Event Dates: 25 — 28 September 2024"
        },
        {
            "name": "2ANNAS",
            "festivalDetails": "Riga International Short Film Festival",
            "country": "Latvia (Riga)",
            "contactDetails": "2annas.lv",
            "time": "Event Dates: 8 — 14 April 2022"
        },
        {
            "name": "39 Rooms Short Film Festival",
            "festivalDetails": "International Independent Short Film Festival",
            "country": "United States",
            "contactDetails": "themodernhotel.com/modernevents/39rooms",
            "time": "Event Dates: 30 September 2024"
        },
        {
            "name": "3 CONTINENTS",
            "festivalDetails": "Festival for filmmakers from Africa, Black and Latin America and Asia",
            "country": "France (Nantes)",
            "contactDetails": "3continents.com",
            "time": "Event Dates: 24 November — 3 December 2023"
        },
        {
            "name": "3D festival",
            "festivalDetails": "International 3D festival",
            "country": "Russia (Moscow)",
            "contactDetails": "m3dfilmfestival.ru",
            "time": "Event Dates: 4 — 6 April 2024"
        },
        {
            "name": "5Point Film Festival",
            "festivalDetails": "Adventure film festival",
            "country": "United States (Carbondale, CO)",
            "contactDetails": "5pointfilm.org",
            "time": "Event Dates: 25 — 28 April 2024"
        },
        {
            "name": "60seconds",
            "festivalDetails": "Short Film Festival in the Copenhagen Metro",
            "country": "Denmark (Copenhagen)",
            "contactDetails": "60sec.org",
            "time": "Event Dates: 10 — 19 November 2023"
        },
        {
            "name": "60 seconds filmfestival",
            "festivalDetails": "International one minute film festival",
            "country": "Pakistan",
            "contactDetails": "60siff.com",
            "time": "Event Dates: 1 — 14 August 2023"
        },
        {
            "name": "60 Seconds or Less",
            "festivalDetails": "Video Festival",
            "country": "United States Chestertown, Maryland",
            "contactDetails": "facebook.com/60SecondsorLessVideoFestival/",
            "time": "Event Dates: 26 — 27 October 2023"
        },
        {
            "name": "AAO Creative Collective Indian Short Film Festival",
            "festivalDetails": "Indian Short Film festival",
            "country": "India Bhubaneswar, Odisha",
            "contactDetails": "aao.tv",
            "time": "Event Dates: 28 March 2021"
        },
        {
            "name": "Abbeville Bird and Nature Festival",
            "festivalDetails": "International film festival related to bird, wildlife or environment wanted!",
            "country": "France- Abbeville",
            "contactDetails": "festival-oiseau-nature.com/en",
            "time": "Event Dates: 20 — 30 April 2024"
        },
        {
            "name": "Abertoir Horror Festival",
            "festivalDetails": "International Horror Film Festival",
            "country": "United Kingdom -Aberystwyth",
            "contactDetails": "abertoir.co.uk",
            "time": "Event Dates: 14 — 19 November 2023"
        },
        {
            "name": "Abitibi-Témiscamingue International Film Festival",
            "festivalDetails": "International Film Festival",
            "country": "Canada - Rouyn-Noranda",
            "contactDetails": "festivalcinema.ca",
            "time": "Event Dates: 28 October — 2 November 2023"
        },
        {
            "name": "Abuja International Film Festival",
            "festivalDetails": "International Film Festival",
            "country": "Nigeria - Abuja",
            "contactDetails": "abujafilmfestival.org",
            "time": "Event Dates: 28 October — 1 November 2024"
        },
        {
            "name": "Academia Film Olomouc",
            "festivalDetails": "International Festival of Science Documentary Films",
            "country": "Czech Republic - Olomouc",
            "contactDetails": "afo.cz",
            "time": "Event Dates: 25 — 30 April 2024"
        },
        {
            "name": "Accolade Global Film Competition",
            "festivalDetails": "Global Film Competition",
            "country": "United States - La Jolla",
            "contactDetails": "accoladecompetition.org",
            "time": "Event Dates: 12 September 2023"
        },
        {
            "name": "accordi @ DISACCORDI",
            "festivalDetails": "International Short Film Festival",
            "country": "Italy - Naples",
            "contactDetails": "accordiedisaccordi.it/en",
            "time": "Event Dates: 11 — 17 November 2024"
        },
        {
            "name": "Ace Film Festival",
            "festivalDetails": "Ace Film Festival is an online awards competition",
            "country": "United States - Duluth, Georgia",
            "contactDetails": "acefilmfestival.net",
            "time": "Event Dates: 9 June 2023 — 9 June 2024"
        },
        {
            "name": "Actress Universe",
            "festivalDetails": "Annual international award for professional actresses",
            "country": "Russia",
            "contactDetails": "actressuniverse.net",
            "time": "Event Dates: 12 January 2023"
        },
        {
            "name": "Adelaide Film Festival",
            "festivalDetails": "Film Festival",
            "country": "Australia - Adelaide",
            "contactDetails": "adelaidefilmfestival.org",
            "time": "Event Dates: 23 October — 3 November 2024"
        },
        {
            "name": "Ad Hoc: Inconvenient Films",
            "festivalDetails": "International Human Rights Film Festival",
            "country": "Lithuania - Vilnius",
            "contactDetails": "nepatoguskinas.lt",
            "time": "Event Dates: 17 — 18 October 2023"
        },
        {
            "name": "AFI FEST",
            "festivalDetails": "American Film Institute's Film Festival is Los Angeles",
            "country": "United States - Los Angeles",
            "contactDetails": "afi.com/afifest/default.aspx",
            "time": "Event Dates: 23 — 27 October 2024"
        },
        {
            "name": "African Smartphone International Film Festival",
            "festivalDetails": "Smartphone International Film Festival in Africa.",
            "country": "Nigeria - Lagos",
            "contactDetails": "africansmartphonefestival.com",
            "time": "Event Dates: 7 — 10 December 2023"
        },
        {
            "name": "AGROFILM",
            "festivalDetails": "International festival of films about rural development.",
            "country": "Slovakia - Nita",
            "contactDetails": "agrofilm.sk",
            "time": "Event Dates: 2 — 7 October 2023"
        },
        {
            "name": "Aguila Film Festival",
            "festivalDetails": "Aguila Film Festival (AFF) is the first online festival in the Philippines.",
            "country": "Philippines Cabu",
            "contactDetails": "aguilafilmfestival.movie.blog",
            "time": "Event Dates: 15 September 2022"
        },
        {
            "name": "Aguilar de Campoo",
            "festivalDetails": "International short film festival.",
            "country": "Spain Aguilar",
            "contactDetails": "aguilarfilmfestival.com",
            "time": "Event Dates: 1 — 9 December 2023"
        },
        {
            "name": "Aichi International Women's Film Festival",
            "festivalDetails": "International Women's Film Festival.",
            "country": "Japan Nagoya",
            "contactDetails": "aiwff.com/2020/index_en",
            "time": "Event Dates: 3 — 6 September 2020"
        },
        {
            "name": "AIFILMFEST",
            "festivalDetails": "International films festival.",
            "country": "Iran - Tehran",
            "contactDetails": "aifilmpro.com/En",
            "time": "Event Dates: 20 — 23 January 2021"
        },
        {
            "name": "Akbank Short Film Festival",
            "festivalDetails": "International Short Film Festival.",
            "country": "Turkey - Istanbul",
            "contactDetails": "akbanksanat.com/en/kisa-film-festivali",
            "time": "Event Dates: 25 March — 4 April 2024"
        },
        {
            "name": "Akrobat",
            "festivalDetails": "International Art Film Festival.",
            "country": "Russia - St. Petersburg",
            "contactDetails": "akrobatfilmfestival.tilda.ws",
            "time": "Event Dates: 14 — 15 September 2023"
        },
        {
            "name": "Albany FilmFest",
            "festivalDetails": "Short Film Festival in Albany.",
            "country": "Albany (New York)",
            "contactDetails": "albanyfilmfest.org",
            "time": "Event Dates: 10 — 13 October 2024"
        },
        {
            "name": "Ale Kino!",
            "festivalDetails": "International Young Audience Film Festival.",
            "country": "Poland",
            "contactDetails": "alekino.com",
            "time": "Event Dates: 24 November — 1 December 2023"
        },
        {
            "name": "Alexandre Trauner ART/FILM Festival",
            "festivalDetails": "International Festival of Fine Arts.",
            "country": "Hungary - Szolnok",
            "contactDetails": "festival.tiszamozi.hu",
            "time": "Event Dates: 15 — 20 October 2024"
        },
        {
            "name": "Alexandria Film Festival",
            "festivalDetails": "Film festival in Alexandria.",
            "country": "Alexandria",
            "contactDetails": "alexfilmfest.com",
            "time": "Event Dates: 9 — 12 November 2023"
        },
        {
            "name": "Alicante Film Festival",
            "festivalDetails": "International Film Festival.",
            "country": "Spain - Alicante",
            "contactDetails": "festivaldealicante.com",
            "time": "Event Dates: 25 May — 1 June 2024"
        },
        {
            "name": "All-Russian Festival of Children's Cinema, Video Creation and Television 'Kinoshag'",
            "festivalDetails": "The festival is held in order to support and develop children's cinema.",
            "country": "Saint Petersburg Russia",
            "contactDetails": "vk.com/kinovideofest",
            "time": "Event Dates: 5 — 20 April 2024"
        },
        {
            "name": "ALL-RUSSIAN FILMS FESTIVAL ABOUT NATURE AND HUMAN «EFir74»",
            "festivalDetails": "Festival dedicated to the topic of ecology and environmental protection.",
            "country": "Chelyabinsk Russia",
            "contactDetails": "eco.kinofest74.ru",
            "time": "1 October 2023 — 31 October 2024"
        },
        {
            "name": "All-Russian Gerasimov Film Festival-Competition",
            "festivalDetails": "All-Russian Festival of Children's Cinema with international participation.",
            "country": "Chelyabinsk Russia",
            "contactDetails": "deti.kinofest74.ru",
            "time": "Event Dates: 17 May 2024"
        },
        {
            "name": "All-Russian Inclusive Film Festival «Invisible Cinema»",
            "festivalDetails": "The first inclusive film festival in the Urals.",
            "country": "Chelyabinsk Russia",
            "contactDetails": "nezrimoekino.ru",
            "time": "Event Dates: 15 — 30 November 2024"
        },
        {
            "name": "All-Russian Youth Film Forum",
            "festivalDetails": "Pitching of debutants.",
            "country": "Moscow Russia",
            "contactDetails": "kinoforum.moviestart.ru",
            "time": " Event Dates: 21 — 23 April 2022"
        },
        {
            "name": "Almaty Indie Film Festival",
            "festivalDetails": "International Independent Low-budget Film Festival.",
            "country": "Kazakhstan Almaty",
            "contactDetails": "almatyfilmfest.com",
            "time": "Event Dates: 19 — 21 July 2024"
        },
        {
            "name": "Almería En Corto",
            "festivalDetails": "International Short Film Festival in Spain.",
            "country": "Spain - Almería",
            "contactDetails": "almeriaencorto.es",
            "time": "Event Dates: 17 — 26 November 2023"
        },
        {
            "name": "Alpinale",
            "festivalDetails": "International short film festival in Nenzing.",
            "country": "Austria - Nenzing",
            "contactDetails": "alpinale.at",
            "time": "Event Dates: 8 — 12 August 2023"
        },
        {
            "name": "ALTER-NATIVE",
            "festivalDetails": "International short film festival.",
            "country": "Romania - Târgu Mureș",
            "contactDetails": "madisz.ro",
            "time": "Event Dates: 1 — 5 November 2023"
        },
        {
            "name": "Alternative film territory",
            "festivalDetails": "International Film Festival.",
            "country": "Saint-Petersburg - Russia",
            "contactDetails": "vk.com/atfkfest",
            "time": "Event Dates: 1 — 15 December 2022"
        },
        {
            "name": "Alytus International Film Festival",
            "festivalDetails": "International Film Festival.",
            "country": "Lithuania - Alytus",
            "contactDetails": "kinoalytus.lt",
            "time": "Event Dates: 7 — 11 November 2021"
        },
        {
            "name": "A man and a sea",
            "festivalDetails": "Television and Documentary Film Festival.",
            "country": "Vladivostok - Russia",
            "contactDetails": "vestiprim.ru/festival",
            "time": "Event Dates: 28 — 30 July 2023"
        },
        {
            "name": "Ambitus",
            "festivalDetails": "International Film Festival.",
            "country": "Saint-Petersburg - Russia",
            "contactDetails": "ambitusfilmfestival.ru",
            "time": "15 February 2024 — 19 February 2025"
        },
        {
            "name": "Ambrosia Food & Drink Film Festival",
            "festivalDetails": "International festival of films about food and drink.",
            "country": "Moscow Russia",
            "contactDetails": "ambrosiafilmfest.com",
            "time": "Event Dates: 12 — 13 October 2024"
        },
        {
            "name": "American Documentary Film Festival and Film Fund",
            "festivalDetails": "International Documentary Film Festival in Palm Springs.",
            "country": "United States - Palm Springs",
            "contactDetails": "americandocumentaryfilmfestival.com",
            "time": "Event Dates: 21 — 25 March 2024"
        },
        {
            "name": "Amiens International Film Festival",
            "festivalDetails": "International Film Festival",
            "country": "France - Amiens",
            "contactDetails": "fifam.fr",
            "time": "Event Dates: 11 — 19 November 2022"
        },
        {
            "name": "Ann Arbor Film Festival",
            "festivalDetails": "International Experimental Film Festival",
            "country": "United States - Ann Arbor",
            "contactDetails": "aafilmfest.org",
            "time": "Event Dates: 26 — 31 March 2024"
        },
        {
            "name": "Annecy International Animation Film Festival",
            "festivalDetails": "International Animation Film Festival in Annecy.",
            "country": "Romania Sfantu ",
            "contactDetails": "annecy.org",
            "time": "Event Dates: 9 — 15 June 2024"
        },
        {
            "name": "Another Hole in the Head",
            "festivalDetails": "Feature film festival in Vallejo.",
            "country": "United States Vallejo ",
            "contactDetails": "sfindie.com",
            "time": "Event Dates: 1 — 25 December 2024"
        },
        {
            "name": "Antakya International Film Festival",
            "festivalDetails": "Antakya Film Festival, is an International, competitive festival.",
            "country": "Turkey - Antakya",
            "contactDetails": "antakyafilmfestivali.com",
            "time": "Event Dates: 24 — 30 October 2024"
        },
        {
            "name": "Antalya Golden Orange Film Festival",
            "festivalDetails": "International Antalya Golden Orange Film Festival.",
            "country": "Turkey Antakya",
            "contactDetails": "antalyaff.com/en",
            "time": "Event Dates: 2 — 9 October 2021"
        },
        {
            "name": "Antares",
            "festivalDetails": "Sochi International Film Festival.",
            "country": "Russia,Sochi",
            "contactDetails": "antares.film",
            "time": "Event Dates: 25 — 30 June 2024"
        },
        {
            "name": "Antenna International Documentary Film Festival",
            "festivalDetails": "International Documentary Film Festival.",
            "country": "Australia - Sydney",
            "contactDetails": "antennafestival.org",
            "time": "Event Dates: 5 — 10 September 2023"
        },
        {
            "name": "Antimatter",
            "festivalDetails": "International Film and Media Arts Film Festival.",
            "country": "Canada - Victoria",
            "contactDetails": "antimatter.ws",
            "time": "Event Dates: 17 — 27 October 2024"
        },
        {
            "name": "ANTOFADOCS",
            "festivalDetails": "International Documentary Film Festival.",
            "country": "Antofagasta Chile",
            "contactDetails": "antofadocs.cl",
            "time": "Event Dates: 14 — 18 November 2023"
        },
        {
            "name": "Ânûû-rû Âboro international film festival",
            "festivalDetails": "International Documentary Film Festival.",
            "country": "Poindimié",
            "contactDetails": "anuuruaboro.com/-Home-",
            "time": "Event Dates: 15 — 23 October 2021"
        },
        {
            "name": "APOX film festival",
            "festivalDetails": "International documentary film festival.",
            "country": "Croatia ",
            "contactDetails": "apoxfest.com",
            "time": "Event Dates: 4 — 11 October 2023"
        }
        ,
        {
            "name": "Apricot Tree International Documentary Film Festival",
            "festivalDetails": "A documentary IFF, where participants live in the Armenian village of Debet.",
            "country": "Debet village (Lori Province, RA)/ Yerevan - Armenia ",
            "contactDetails": "atieff.org",
            "time": "Event Dates: 24 — 31 August 2024"
        },
        {
            "name": "APSA",
            "festivalDetails": "Asia Pacific Screen Awards.",
            "country": "Australia - Brisbane",
            "contactDetails": "asiapacificscreenacademy.com",
            "time": "Event Dates: 26 November 2020"
        },
        {
            "name": "'AQUARIUS' Russian Short Film Festival",
            "festivalDetails": "'Festival Plus' presents project in Moscow 'AQUARIUS'.",
            "country": "Russia - Moscow ",
            "contactDetails": "vodoleyfestival.ru",
            "time": "Event Dates: 18 — 20 October 2024"
        },
        {
            "name": "ARCTIC OPEN",
            "festivalDetails": "Arctic Countries Film Festival ARCTIC OPEN",
            "country": "Arkhangelsk - Russia",
            "contactDetails": "arctic-open.com",
            "time": "Event Dates: 7 — 10 December 2023"
        },
        {
            "name": "ARFF Barcelona",
            "festivalDetails": "Independent Festival for all genre Feature & Short Creations.",
            "country": "Spain - Barcelona",
            "contactDetails": "aroundfilms.com/about",
            "time": "Event Dates: 13 — 17 March 2025"
        }
    ];


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            {status === 1 ? (
                <HeaderloginLite />
            ) : (
                <HomeHeader />
            )}
            <section className="container mb-4" style={{ marginTop: "125px" }}>
                <div className="text-center mb-4 ">
                    <h2 className="allTextColor">Film Festivals Aorund the Globe</h2>

                </div>
                <div className="table-responsive">
                    <table className="custom-responsive-table">
                        <thead>
                            <tr>
                                {/* <th>SN</th> */}
                                <th>Name</th>
                                <th>Festival Details</th>
                                <th>Country</th>
                                <th>Contact Details</th>
                                {/* <th>Time</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {filmFestivals.map((association, index) => (
                                <tr key={index}>
                                    {/* <td >{association.sn}</td> */}
                                    <td >{association.name}</td>
                                    <td >{association.festivalDetails}</td>
                                    <td >{association.country}</td>
                                    <td >{association.contactDetails}</td>
                                    {/* <td >{association.time}</td> */}

                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </section>
            <HomeFooter />
            <SubFooter />
        </Fragment >
    );
};

export default FilmFestivals;
