import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer } from "react-toastify";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";
import { add } from "../../../action/Admin.action";
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { setAlert } from '../../../slices/home';
import { postJobsAction, getPostJobsAction } from "../../../action/Admin.action";
import { toast } from "react-toastify";

const AddEditJob = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { state } = useLocation();

    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        jobName: '',
        companyName: '',
        desc: '',
        location: '',
        jobType: '',

    });
    const [formErrors, setFormErrors] = useState({});
    const [jobData, setJobData] = useState([]);
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {

            event.preventDefault();
            event.stopPropagation();
            setValidated(true);

        } else {
            event.preventDefault();
            event.stopPropagation();
            //setValidated(false);
            setDisabled(true);
            let formDataData = { ...formData };

            let postData = new FormData();
            for (let x in formDataData) {

                let item = formDataData[x];
                postData.append(x, item);
            }

            let msg = "";
            if (formData._id) {
                msg = "You have successfully updated Job.";
            } else {
                msg = "You have successfully added new Job.";
            }
            let resp = await postJobsAction(postData);
            if (resp.code === 200) {

                // dispatch(setAlert({ open: true, severity: "success", msg: msg, type: '' }));
                toast.success(msg, { autoClose: 1000 });
                setTimeout(() => {
                    navigate(`${process.env.PUBLIC_URL}/user/dashboard`, { state: "" });
                }, 2000);
            } else {
                setDisabled(false);
                toast.error(resp.msg, { autoClose: 1000 });
                // dispatch(setAlert({ open: true, severity: "danger", msg: resp.msg, type: '' }));
            }
            return false;
        }

    };
    const handleChange = (name, event) => {
        setFormData({ ...formData, [name]: event.target.value });
    };
    const getData = async () => {
        let resp = await getPostJobsAction({ status: 1 });
        if (resp.code === 200) {
            setJobData(resp.data);
            // setRoleList(resp.data);
        }
    }


    useEffect(() => {
        getData();
        if (state) {
            let data = state;
            let editData = {
                _id: data._id,
                jobName: data.jobName,
                companyName: data.companyName,
                desc: data.desc,
                location: data.location,
                jobType: data.jobType,
                status: data.status
            }
            setFormData(editData);
        }

    }, []);

    const cancel = async () => {

        navigate(`${process.env.PUBLIC_URL}/user/dashboard`);
    }
    return (
        <Fragment>
            <Container fluid className="p-0">
                <Container className="d-flex justify-content-center align-items-center mt-4">
                    <div
                        className="form-container addEditJobContainer"
                    >
                        <h3
                            className="allTextColor"
                            style={{
                                borderBottom: "1px solid #313A47",
                            }}
                        >
                            {formData._id ? 'Edit Job' : 'Add Job'}
                        </h3>
                        <Form className="userForm" noValidate validated={validated} onSubmit={e => handleSubmit(e)}>
                            <Row className="mb-3">
                                <Col xs="12" md={6} lg="6">
                                    {/* <Form.Group className="mb-3"> */}
                                    <Form.Label className="allTextColor mb-2">Job Title<span className="star"> *</span></Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="jobName"
                                        value={formData.jobName ? formData.jobName : ""}
                                        onChange={e => handleChange('jobName', e)}
                                        placeholder="Enter Job Title"
                                        autocomplete="off"
                                        minLength={1}
                                        maxLength={127}
                                        pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{1,127}$"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid Job Name
                                    </Form.Control.Feedback>
                                    {/* </Form.Group> */}
                                </Col>
                                <Col xs="12" md={6} lg="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="allTextColor mb-2 ">Company Name<span className="star"> *</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="companyName"
                                            value={formData.companyName ? formData.companyName : ""}
                                            onChange={e => handleChange('companyName', e)}
                                            placeholder="Enter Company Name"
                                            autocomplete="off"
                                            required
                                            minLength={1}
                                            maxLength={127}
                                            pattern="^^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{1,127}$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Company Name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">

                                <Col xs="12" md={6} lg="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="allTextColor mb-2" >Location<span className="star"> *</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="location"
                                            value={formData.location ? formData.location : ""}
                                            onChange={e => handleChange('location', e)}
                                            placeholder="Enter Job Location"
                                            autocomplete="off"
                                            required
                                            minLength={3}
                                            maxLength={200}
                                            pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{3,200}$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Location
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col xs="12" md={6} lg="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="allTextColor mb-2 " >Description<span className="star"> *</span></Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            name="desc"
                                            value={formData.desc ? formData.desc : ""}
                                            onChange={e => handleChange('desc', e)}
                                            placeholder="Enter Job Description"
                                            required
                                            minLength={3}
                                            maxLength={1000}
                                            pattern="^[a-zA-Z][a-zA-Z0-9\s@#$%&*.,\-]{3,1000}$"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Description
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col xs="12" md={6} lg="6">
                                    <Form.Group className="mb-3">
                                        <Form.Label className="allTextColor mb-2">Job Type<span className="star"> *</span></Form.Label>
                                        <Form.Select aria-label="Default select example" className="filedbg customC-select" value={formData.jobType ? formData.jobType : ""} onChange={e => handleChange('jobType', e)} required>
                                            <option value="">Select Job Type</option>
                                            <option value="Part Time">Part Time</option>
                                            <option value="Full Time">Full Time</option>
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Job Type.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                {formData._id && (
                                    <Col xs="12" md={6} lg="6">
                                        <Form.Group className="mb-3">
                                            <Form.Label className="allTextColor mb-2">Status<span className="star"> *</span></Form.Label>
                                            <Form.Select
                                                aria-label="Default select example"
                                                className="filedbg customC-select"
                                                value={formData.status > -1 ? formData.status : ""}
                                                onChange={e => handleChange('status', e)}
                                                required
                                            >
                                                <option value="">Select Status</option>
                                                <option value="1">Active</option>
                                                <option value="0">Inactive</option>
                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid Status.
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col>
                                )}
                            </Row>
                            <button className="btn createbtnColor" type="submit" disabled={isDisabled}>
                                Save
                            </button>{' '}
                            <button onClick={e => cancel()} className=" btn btnColor">Cancel</button>
                        </Form>
                    </div>
                </Container>
            </Container>
        </Fragment>
    )

};

export default AddEditJob;