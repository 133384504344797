import React, { Fragment, useEffect } from "react";
import HomeHeader from "../Header/HomeHeader";
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
import HeaderloginLite from "../Header/HeaderloginLite";

const TermsAndConditions = ({ selected }) => {
    const status = JSON.parse(localStorage.getItem("status"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            {status === 1 ? (
                <HeaderloginLite />
            ) : (
                <HomeHeader />
            )}
            <section className="terms-and-conditions-section">
                <div className="terms-container">
                    <h2 className="text-center allTextColor" style={{ paddingBottom: '15px' }}>Terms and Conditions</h2>
                    <p className="intro-text">
                        Last Updated: October 22,2024 <br></br>
                        Welcome to FILM CREW! By using our application, you agree to the following terms and conditions. Please read them carefully.
                    </p>
                    {/* <p className="intro-text">By accessing or using the App, or by clicking to accept or agree to this ToU if presented to you in a user interface, you agree to be bound by this ToU. If you do not agree to these terms, you may not use the App.</p> */}
                    <p className="intro-text"><span className="spanText">1.Acceptance of Terms:</span><br></br> By accessing or using Film Crew, you agree to be bound by these Terms and Conditions and our Privacy Policy. If you do not agree, please do not use the app.</p>
                    <p className="intro-text"> <span className="spanText">2.User Accounts:</span><br></br>. Registration: To use certain features, you may need to create an account. You agree to provide accurate and complete information and to update it as necessary.<br></br>. Account Security: You are responsible for maintaining the confidentiality of your password and account. Notify us immediately of any unauthorized use.
                    </p>
                    <p className="intro-text"> <span className="spanText">3.User Content:</span><br></br>. Ownership: You retain ownership of the content you create and share. However, by submitting content, you grant us a non-exclusive, worldwide, royalty-free license to use, display, and distribute it.<br></br>. Prohibited Content: You must not post content that is offensive, infringes copyright, or violates any laws. We reserve the right to remove such content.
                    </p>
                    <p className="intro-text"> <span className="spanText">4.Reporting Inappropriate Content:</span><br></br> If you encounter any inappropriate content, please report it through our reporting feature. We will review and take appropriate action.
                    </p>
                    <p className="intro-text"> <span className="spanText">5.Security Measures:</span><br></br> We implement various security measures, including Two-Factor Authentication (2FA), to protect your account. However, you are responsible for your own account security.
                    </p>
                    <p className="intro-text"> <span className="spanText">6.License Terms:</span><br></br> You are granted a limited, non-transferable license to use the app. You may not reproduce, distribute, or create derivative works from any part of the app.
                    </p>
                    <p className="intro-text"> <span className="spanText">7.Intellectual Property:</span><br></br> All intellectual property rights related to the app and its content are owned by Picture Company. You may not use our trademarks or copyrighted materials without permission.
                    </p>
                    <p className="intro-text"> <span className="spanText">8.Termination:</span><br></br> We reserve the right to terminate your account for violations of these Terms. You may appeal the decision by contacting our support team.
                    </p>
                    <p className="intro-text"> <span className="spanText">9.Limitation of Liability:</span><br></br> Picture Company is not liable for any damages arising from your use of the app, including indirect, incidental, or consequential damages.
                    </p>
                    <p className="intro-text"> <span className="spanText">10.Changes to Terms:</span><br></br> We may update these Terms from time to time. You will be notified of any significant changes. Continued use of the app after changes indicates your acceptance of the new terms.
                    </p>
                    <p className="intro-text"> <span className="spanText">11.Governing Law:</span><br></br> These Terms shall be governed by the laws of Maharashtra, India, without regard to its conflict of law principles.
                    </p>
                    <p className="intro-text"> <span className="spanText">12.Contact Information:</span><br></br> For any questions or concerns about these Terms, please contact us at +91 98200 86456.
                    </p>
                </div>
            </section>
            <HomeFooter />
            <SubFooter />
        </Fragment >
    );
};

export default TermsAndConditions;
