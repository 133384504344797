import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { getMemberDetails, addEditEnquiryAction } from "../../../action/Admin.action";
import { Container, Row, Col, Card, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";
import freepik from "../../../assets/images/frontPageImg/portfolioimages.png";
import { imgPath, checkFile } from "../../../admin/common/function";
import { Document, Page, pdfjs } from 'react-pdf';
import LoginWithOtp from "./LoginWithOtp";
import pdfIcon from "../../../assets/images/frontPageImg/pdfIcon.jpg"
import default_profile from "../../../assets/images/frontPageImg/default_profile.png"
import HomeFooter from "../Footer/HomeFooter";
import SubFooter from "../Footer/SubFooter";
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();
const CategoryProfileListing = ({ selected }) => {
    const [pdfError, setPdfError] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [categoryDetailsData, setCategoryDetailsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [showApplyModal, setShowApplyModal] = useState(false);
    const handleOpenApplyModal = () => setShowApplyModal(true);
    const handleCloseApplyModal = () => setShowApplyModal(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const status = JSON.parse(localStorage.getItem("status"));
    const [formEnquryData, setFormEnqueryData] = useState({
        description: '',
        memberId: ''
    });
    const categoryId = location.state?.id;
    const categoryName = location.state?.categoryName;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageClick = (imagePath) => {
        setSelectedImage(imagePath); // Set the clicked image path
        setIsModalOpen(true); // Open the modal
    };

    const closeModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedImage(null); // Reset selected image
    };

    const fetchCategoryDetailsData = async () => {
        setLoading(true);
        const response = await getMemberDetails({ _id: categoryId });
        if (response.code === 200) {


            setCategoryDetailsData(response.data);
        }
        setLoading(false);
    };

    // pdfjs.GlobalWorkerOptions.workerSrc = `/src/pdf.worker.js`;

    const handleSendEnqueryNow = (Id) => {
        setFormEnqueryData({ ...formEnquryData, memberId: Id });
        setShowModal(true);
    };
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setValidated(false);
            setDisabled(true);

            const postData = {
                description: formEnquryData.description,
                memberId: formEnquryData.memberId
            };

            try {
                const response = await addEditEnquiryAction(postData);

                if (response.code === 200) {
                    setSuccessMessage("Your enquiry has been sent successfully!");
                    setErrorMessage('');

                    setFormEnqueryData({
                        description: '',
                        memberId: ''
                    });
                    setTimeout(() => {
                        setSuccessMessage('');
                        setShowModal(false);
                    }, 2000);
                } else {
                    setErrorMessage(response.msg);
                    setSuccessMessage('');
                }
            } catch (error) {
                setErrorMessage("Failed to send your enquiry. Please check and try again.");
                setSuccessMessage('');
                console.error("Error submitting send your enquiry:", error);
            } finally {
                setDisabled(false);
            }
        }
    };
    const handleSendEnqueryInputChange = (e) => {
        const { name, value } = e.target;
        setFormEnqueryData({ ...formEnquryData, [name]: value });
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSuccessMessage('');
        setErrorMessage('');
        setFormEnqueryData({
            description: '',
            memberId: ''
        });
    };

    useEffect(() => {
        if (categoryId) {
            fetchCategoryDetailsData();
        }
    }, [categoryId]);
    // const status = JSON.parse(localStorage.getItem("status"));
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="search-barDetail-section">
                <Container className="search-bar">
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '5px' }}>
                        <Col md={8} >
                            <Form style={{ paddingTop: '25px' }}>
                                <Row className="g-2">
                                    <Col xs={12} md={2}>

                                    </Col>
                                    <Col xs={12} md={4}>

                                    </Col>

                                </Row>
                            </Form>
                        </Col>
                        <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col>
                    </Row>
                </Container>
                <Container className="search-bar">
                    <Card className="profile-container">

                        <div className=" card-header-section">
                            <div className="header-content">

                            </div>
                        </div>

                        <Row className="">

                            <Col md={8}>

                                {/* <Card className="profile-card mb-4"> */}

                                {categoryDetailsData.length > 0 && (
                                    <div>
                                        {/* Profile Image Section */}
                                        <div className="profile-image-wrapper" style={{ marginLeft: '10px', padding: '10px' }}>
                                            <img
                                                // src={imgPath(categoryDetailsData[0].userProfile) || { default_profile }} // Default image if no profile image is available
                                                src={categoryDetailsData[0].userProfile ? imgPath(categoryDetailsData[0].userProfile) : default_profile}
                                                alt="User Profile"
                                                className="profile-image"
                                                style={{
                                                    width: '100px',      // Adjust size as needed
                                                    height: '100px',
                                                    borderRadius: '50%', // Makes the image circular
                                                    objectFit: 'cover',  // Ensures image is fully contained
                                                    border: '2px solid #ddd',
                                                }}
                                            />
                                        </div>

                                        {/* Content Section */}
                                        <div className="" style={{ padding: '0px 26px 0px 25px' }}>
                                            <h3 className="profile-title-name">{categoryDetailsData[0].businessContactName}</h3>

                                            <div className="d-flex align-items-center mb-3 rating-section">
                                                <p className="subtitle">{categoryName}</p>
                                            </div>

                                            <p className="description">
                                                {categoryDetailsData[0].description}
                                            </p>
                                        </div>
                                    </div>
                                )}
                                {/* </Card> */}

                                <h4 className="portfolio-title mt-4">Portfolio</h4>
                                {/* <div className="portfolio-grid">
                                    <Card className="portfolio-card">
                                        <img src={freepik} className="portfolio-img"></img>
                                    </Card>
                                </div> */}

                                {/* <div className="portfolio-grid">
                                    {categoryDetailsData && categoryDetailsData.length > 0 && categoryDetailsData[0].portFolio && categoryDetailsData[0].portFolio.length > 0 ? (
                                        categoryDetailsData[0].portFolio.map((image, index) => (
                                            <Card className="portfolio-card" key={index}>
                                                {console.log('image', image)}
                                                <img src={imgPath(image)} alt={`Portfolio ${index}`} className="portfolio-img" />
                                            </Card>
                                        ))
                                    ) : (
                                        <Card className="portfolio-card">
                                            <img src={freepik} alt="Default Portfolio" className="portfolio-img" />
                                        </Card>
                                    )}
                                </div> */}


                                <div className="portfolio-grid">
                                    {categoryDetailsData && categoryDetailsData.length > 0 && categoryDetailsData[0].portfolioData && categoryDetailsData[0].portfolioData.length > 0 ? (
                                        categoryDetailsData[0].portfolioData.map((image, index) => {
                                            const isPdf = image.filePath.endsWith('.pdf'); // Check if the file is a PDF
                                            console.log('PDF URL:', imgPath(image.filePath), 'rrrr', isPdf);
                                            return (
                                                <Card className="portfolio-card" key={index}>
                                                    {/* {console.log('image', image)} */}
                                                    {isPdf ? (
                                                        // Render PDF preview if it's a PDF file
                                                        <a
                                                            href={imgPath(image.filePath)}
                                                            target="blank"
                                                            download
                                                        >
                                                            <img
                                                                src={pdfIcon}  // Display PDF icon
                                                                alt={`PDF Preview ${index}`}
                                                                style={{ width: '120px', height: '120px', borderRadius: '10px', objectFit: 'cover', cursor: 'pointer' }}
                                                            />
                                                        </a>
                                                    ) : (
                                                        // Render image if it's not a PDF
                                                        <img
                                                            src={imgPath(image.filePath)}
                                                            alt={`Portfolio ${index}`}
                                                            className="portfolio-img"
                                                            onClick={() => handleImageClick(imgPath(image.filePath))} // Open modal with large image on click
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        // <img src={imgPath(image.filePath)} alt={`Portfolio ${index}`} className="portfolio-img" />
                                                    )}
                                                </Card>
                                            );
                                        })
                                    ) : (
                                        <Card className="portfolio-card">
                                            <div
                                                className="file-upload-containerDetailsPage"
                                            // onClick={() => document.getElementById('productImageInput').click()}
                                            >
                                                <Form.Control
                                                    id="productImageInput"

                                                    name="productImg"
                                                    accept="image/png, image/jpeg"
                                                    // onChange={(e) => handleFileUpload(e.target.files[0], '2')}
                                                    className="file-inputDetailsPage"
                                                />
                                                <div className="plus-icon">+</div>
                                            </div>
                                        </Card>
                                    )}
                                </div>
                                {/* <h4 className="portfolio-title mt-4">Product</h4>
                                <div className="portfolio-grid">
                                    {categoryDetailsData && categoryDetailsData.length > 0 && categoryDetailsData[0].productData && categoryDetailsData[0].productData.length > 0 ? (
                                        categoryDetailsData[0].productData.map((image, index) => {
                                            const isPdf = image.filePath.endsWith('.pdf'); 
                                            console.log('PDF URL:', imgPath(image.filePath), 'rrrr', isPdf);
                                            return (
                                                <Card className="portfolio-card" key={index}>
                                                   
                                                    {isPdf ? (
                                                       
                                                        <img
                                                            src={pdfIcon} 
                                                            alt={`Excel Preview ${index}`}
                                                            style={{ width: '120px', height: '120px', borderRadius: '10px', objectFit: 'cover' }}
                                                        />
                                                    ) : (
                                                      
                                                        <img
                                                            src={imgPath(image.filePath)}
                                                            alt={`Product ${index}`}
                                                            className="portfolio-img"
                                                            onClick={() => handleImageClick(imgPath(image.filePath))} 
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                       
                                                    )}
                                                </Card>
                                            );
                                        })
                                    ) : (
                                        <Card className="portfolio-card">
                                            <div
                                                className="file-upload-containerDetailsPage"
                                            
                                            >
                                                <Form.Control
                                                    id="productImageInput"

                                                    name="productImg"
                                                    accept="image/png, image/jpeg"
                                                  
                                                    className="file-inputDetailsPage"
                                                />
                                                <div className="plus-icon">+</div>
                                            </div>
                                        </Card>
                                    )}
                                </div> */}
                                {categoryDetailsData && categoryDetailsData.length > 0 && (
                                    <>
                                        {/* Conditional Title */}
                                        <h4 className="portfolio-title mt-4">
                                            {categoryDetailsData[0].userType === 3 ? 'Product' : 'Resume'}
                                        </h4>

                                        {/* Portfolio Grid */}
                                        <div className="portfolio-grid">
                                            {categoryDetailsData[0].userType === 3 && categoryDetailsData[0].productData && categoryDetailsData[0].productData.length > 0 ? (
                                                categoryDetailsData[0].productData.map((image, index) => (
                                                    <Card className="portfolio-card" key={index}>
                                                        <img
                                                            src={imgPath(image.filePath)}
                                                            alt={`Product ${index}`}
                                                            className="portfolio-img"
                                                            onClick={() => handleImageClick(imgPath(image.filePath))}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </Card>
                                                ))
                                            ) : categoryDetailsData[0].userType === 4 && categoryDetailsData[0].crewResume ? (
                                                <Card className="portfolio-card">
                                                    <a href={imgPath(categoryDetailsData[0].crewResume)} target="blank" download>
                                                        <img
                                                            src={pdfIcon}
                                                            alt="Resume PDF"
                                                            style={{ width: '120px', height: '120px', borderRadius: '10px', objectFit: 'cover', cursor: 'pointer' }}
                                                        />
                                                    </a>
                                                </Card>
                                            ) : (
                                                <Card className="portfolio-card">
                                                    <div
                                                        className="file-upload-containerDetailsPage"

                                                    >
                                                        <Form.Control
                                                            id="productImageInput"

                                                            name="productImg"
                                                            accept="image/png, image/jpeg"

                                                            className="file-inputDetailsPage"
                                                        />
                                                        <div className="plus-icon">+</div>
                                                    </div>
                                                </Card>
                                            )}
                                        </div>
                                    </>
                                )}
                            </Col>

                            <Col md={4}>
                                <div className="contact-card p-4">
                                    <Card.Body>
                                        {/* <div className="button-groupe">
                                            <Button variant="danger">Send Enquiry</Button>
                                            <Button variant="success">Call Now</Button>
                                        </div> */}
                                        <div className="button-groupe">
                                            <button
                                                className="btn btnColor"
                                                onClick={() => {
                                                    if (status === 1) {
                                                        handleSendEnqueryNow(categoryDetailsData[0]._id);
                                                    } else {
                                                        navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);
                                                        // handleOpenApplyModal();
                                                    }
                                                }}
                                                style={{ fontSize: '12px' }}
                                            >
                                                Send Enquiry
                                            </button>
                                        </div>
                                        {categoryDetailsData.length > 0 && (
                                            <>
                                                <p className="contact-info">
                                                    <i className="fas fa-map-marker-alt"></i> {categoryDetailsData[0].businessAddress},{categoryDetailsData[0].businessCity}-{categoryDetailsData[0].businessPostalCode}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-phone"></i> {categoryDetailsData[0].countryCode} {categoryDetailsData[0].businessContactNumber}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-envelope"></i> {categoryDetailsData[0].email}
                                                </p>
                                                <p className="contact-info">
                                                    <i className="fas fa-globe"></i>    {categoryDetailsData[0].website ? categoryDetailsData[0].website : " N/A"}
                                                </p>
                                            </>
                                        )}
                                    </Card.Body>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                </Container>
                {
                    status === 1 ? (
                        <></>
                    ) : (
                        <>
                            <HomeFooter />
                            <SubFooter />
                        </>
                    )
                }
            </section>
            <Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title>Send Enquiry </Modal.Title>
                </Modal.Header>
                <Modal.Body className='liteModal-content'>
                    <Form className="userForm" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formDesc" className="mt-3">
                            <Form.Label className="allTextColor mb-2">Description<span className="star"> *</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="description"
                                value={formEnquryData.description}
                                onChange={handleSendEnqueryInputChange}
                                placeholder="Enter a description"
                                autocomplete="off"
                                minLength={10}
                                maxLength={500}
                                pattern="^(?!\s*$).+"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Description.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

                        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}


                        <div className="mt-4">
                            <Button className="btnColor" onClick={handleCloseModal}>
                                Close
                            </Button>{' '}
                            <Button className="createbtnColor" type="submit" style={{ marginLeft: '5px' }} >
                                Save
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Modal for displaying the image in large size */}
            <Modal show={isModalOpen} onHide={closeModal} centered>
                <Modal.Header className="ImageModelHeader" closeButton>
                    {/* <Modal.Title>Image Preview</Modal.Title> */}
                </Modal.Header>
                <Modal.Body style={{ padding: 0 }}>
                    {selectedImage && (
                        <img
                            src={selectedImage}
                            alt="Large Preview"
                            style={{ width: '100%', height: 'auto' }}
                        />
                    )}
                </Modal.Body>
            </Modal>

            <Modal show={showApplyModal} onHide={handleCloseApplyModal} size="lg" centered>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title className='text-white'>Login / Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body className='loginModelBg'>
                    <LoginWithOtp />
                </Modal.Body>
            </Modal>
        </Fragment >
    );
};

export default CategoryProfileListing;
