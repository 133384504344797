import React, { memo, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Logo from "../../../assets/images/frontPageImg/blackLogo.png";
import userImg from "../../../assets/images/frontPageImg/userImg2.png";
import { Nav, Dropdown, Modal } from 'react-bootstrap';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import ProfileEdit from "../Pages/ProfileEdit"
import { imgPath } from "../../../admin/common/function";

function Header() {
  const navigate = useNavigate();
  const [showProfileModal, setShowProfileModal] = useState(false);
  const location = useLocation();
  const handleOpenProfileModal = () => setShowProfileModal(true);
  const handleCloseProfileModal = () => setShowProfileModal(false);

  const handleCategoryNavigation = () => {
    navigate(`${process.env.PUBLIC_URL}/user/category`);
  };
  const status = JSON.parse(localStorage.getItem("status"));
  const loginDb = JSON.parse(localStorage.getItem("login"));

  const handleLogout = () => {
    localStorage.clear();

    setTimeout(() => {
      window.location.href = "/";
    });
  };
  return (
    <Navbar expand="lg" className="theme-header">
      <Container fluid>
        <Navbar.Brand className="mobilelogo">
          {status === 1 ? (
            <Link to={`${process.env.PUBLIC_URL}/user/dashboard`}>
              <img src={Logo} alt="" style={{ height: "65px" }} />
            </Link>
          ) : (
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <img src={Logo} alt="" style={{ height: "65px" }} />
            </Link>
          )}
        </Navbar.Brand>
        {status === 1 && (
          <>
            <Navbar.Toggle aria-controls="navbar-nav" />
            <Navbar.Collapse id="navbar-nav" className="justify-content-end">

              <Nav className="ml-auto nav-links loginNavLinkCOlor" style={{ marginRight: '30px' }} >
                <Nav.Link onClick={() => navigate(`${process.env.PUBLIC_URL}/`)} className={location.pathname === `${process.env.PUBLIC_URL}/` ? 'active' : ''}>Home</Nav.Link>
                <Nav.Link onClick={() => navigate(`${process.env.PUBLIC_URL}/user/dashboard`)} className={location.pathname === `${process.env.PUBLIC_URL}/user/dashboard` ? 'active' : ''}>Dashboard</Nav.Link>
                <Nav.Link onClick={() => navigate(`${process.env.PUBLIC_URL}/user/category`)} className={location.pathname === `${process.env.PUBLIC_URL}/user/category` ? 'active' : ''}>Category</Nav.Link>
                <Nav.Link onClick={() => navigate(`${process.env.PUBLIC_URL}/job`)} className={location.pathname === `${process.env.PUBLIC_URL}/job` ? 'active' : ''}>Jobs</Nav.Link>
              </Nav>

              <Dropdown align="end">
                <Dropdown.Toggle
                  as="a"
                  className="user-icon"
                  style={{ marginRight: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }}
                >
                  <img
                    src={loginDb?.userProfile ? imgPath(loginDb.userProfile) : userImg}
                    alt="User Icon"
                    style={{ width: '24px', height: '24px', borderRadius: '50%' }}
                  />
                  <span style={{ marginTop: '4px', fontSize: '12px', color: '#212D40' }}>

                    {/* {loginDb.businessContactName ? loginDb.businessContactName : 'Username'} */}
                    {loginDb.businessContactName
                      ? (loginDb.businessContactName.length > 10
                        ? `${loginDb.businessContactName.substring(0, 10)}...`
                        : loginDb.businessContactName)
                      : 'Username'
                    }
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item className="text-black" onClick={handleOpenProfileModal}>
                    Profile
                  </Dropdown.Item>
                  <Dropdown.Item className='text-black' onClick={handleLogout}>Logout</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Navbar.Collapse>
            <Modal
              show={showProfileModal}
              onHide={handleCloseProfileModal}
              size="lg"
              aria-labelledby="profile-modal-title"
              centered
            >
              <Modal.Header className='editProfielCardHeader' closeButton>
                <Modal.Title id="profile-modal-title" className='editProfielCardHeader'>Edit Profile</Modal.Title>
              </Modal.Header>
              <Modal.Body className='editProfielCard' >
                <ProfileEdit onClose={handleCloseProfileModal} />
              </Modal.Body>
            </Modal>
          </>
        )}
      </Container>
    </Navbar>
  );
}

export default memo(Header);
