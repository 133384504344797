import React, { Fragment, useState, useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { Modal, Button, Form } from 'react-bootstrap';
import { ToastContainer } from "react-toastify";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";
import { useDispatch } from "react-redux";
import { getPostJobsAction, _deleteAction, getEnquiryAction } from "../../../action/Admin.action";
import { Link, useNavigate } from "react-router-dom";
import { setAlert } from "../../../slices/home";
import { confirmAlert } from "react-confirm-alert";
import PostApplyDetailsModel from "./PostApplyDetailsModel";
import SenderEnquiryDetailModel from "./SenderEnquiryDetailModel";

const UserDashboard = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [jobData, setJobData] = useState([]);
    const [enquiryData, setEnquiryList] = useState([]);
    const [jobModalOpen, setJobModalOpen] = useState(false);
    const [enquiryModalOpen, setEnquiryModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedEnquiry, setSelectedEnquiry] = useState(null);

    const openJobModal = (job) => {
        setSelectedJob(job);
        setJobModalOpen(true);
    };

    const closeJobModal = () => {
        setSelectedJob(null);
        setJobModalOpen(false);
    };

    const openEnquiryModal = (item) => {
        setSelectedEnquiry(item);
        setEnquiryModalOpen(true);
    };

    const closeEnquiryModal = () => {
        setSelectedEnquiry(null);
        setEnquiryModalOpen(false);
    };
    const getData = async () => {
        let resp = await getPostJobsAction();
        if (resp.code === 200) {
            // console.log('job', resp.data);
            setJobData(resp.data);
            // setRoleList(resp.data);
        }

        let resp2 = await getEnquiryAction();
        if (resp2.code === 200) {
            setEnquiryList(resp2.data);
        }
    }
    const _deleteEnquiry = async (id) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        dispatch(
                            setAlert({
                                open: false,
                                severity: "success",
                                msg: "Loading...",
                                type: "loader",
                            })
                        );

                        const resp = await _deleteAction({ _id: id, type: "enquiry" });
                        if (resp.code === 200) {
                            // props.getDataList();
                            dispatch(
                                setAlert({
                                    open: true,
                                    severity: "success",
                                    msg: "You have successfully deleted  enquiry.",
                                    type: "",
                                })
                            );
                            getData();
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };
    const _delete = async (id) => {
        confirmAlert({
            title: "Confirm to Delete",
            message: "Are you sure to do this.",
            buttons: [
                {
                    label: "Yes",
                    onClick: async () => {
                        dispatch(
                            setAlert({
                                open: false,
                                severity: "success",
                                msg: "Loading...",
                                type: "loader",
                            })
                        );

                        const resp = await _deleteAction({ _id: id, type: "postJobs" });
                        if (resp.code === 200) {
                            // props.getDataList();
                            dispatch(
                                setAlert({
                                    open: true,
                                    severity: "success",
                                    msg: "You have successfully deleted.",
                                    type: "",
                                })
                            );
                            getData();
                        }
                    },
                },
                {
                    label: "No",
                },
            ],
        });
    };
    useEffect(() => {
        getData();


    }, [])
    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString('en-GB', options); // 'en-GB' for dd/mm/yyyy format
    };

    const truncateText = (text, maxLength) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    return (
        <Fragment>
            <Container fluid className="p-0">
                {/* <Header /> */}

                <Container  >
                    {/* <div className="dashboard-header mt-4">
                        <h1>Google Ads</h1>
                    </div> */}
                    <div className="dashboard-stats mt-4">
                        <Row className="stat-box-container text-center">
                            {/* {stats.map((stat, idx) => (
                                <Col key={idx} sm={6} md={4} lg={2} className="stat-box">
                                    <div className="stat-value">{stat.value}</div>
                                    <div className="stat-title">{stat.label}</div>
                                </Col>
                            ))} */}
                        </Row>
                    </div>
                    <div className="dashboard-container">
                        <div className="grid-container">
                            {/* My Bookings */}
                            <div className="grid-item bookings pb-5">
                                <div className="d-flex justify-content-between align-items-center">
                                    <h5 className="allTextColor" style={{ marginBottom: '18px', marginTop: '20px', }}>Jobs</h5>
                                    <Link className="btn btnColor" to={`${process.env.PUBLIC_URL}/user/addJob`} style={{ height: '33px' }}>Add Job</Link>
                                </div>
                                <Table className="table-dar">
                                    <thead>
                                        <tr >
                                            <th scope="col">Job Title</th>
                                            <th scope="col">Company Name</th>
                                            <th scope="col">Location</th>
                                            <th scope="col">Job Type</th>
                                            <th scope="col">Description</th>
                                            <th scope="col">Status</th>
                                            <th scope="col" style={{ width: 120 }}>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {jobData.length > 0 ? jobData.map((job, idx) => (
                                            <tr key={job._id}>
                                                <td style={{ paddingTop: '7px' }}>{job.jobName}</td>
                                                <td style={{ paddingTop: '7px' }}>{job.companyName}</td>
                                                <td style={{ paddingTop: '7px' }}>{job.location}</td>
                                                <td style={{ paddingTop: '7px' }}>{job.jobType}</td>
                                                <td style={{ paddingTop: '7px' }}>{truncateText(`${job.desc}`, 15)}</td>
                                                <td style={{ paddingTop: '7px' }}>
                                                    <span className={job.status === 1 ? "status-gree" : "status-red"}>
                                                        {job.status === 1 ? "Active" : "Inactive"}
                                                    </span>
                                                </td>
                                                <td className="table-actions">
                                                    <button className="btnColor" size="sm" onClick={(e) => navigate(`${process.env.PUBLIC_URL}/user/editJob`, { state: job })} >✎</button>
                                                    <button className=" btn-danger" size="sm" onClick={(e) => _delete(job._id)}  >✕</button>
                                                    <button className=" createbtnColor" size="sm" style={{ margin: '5px' }} title="Applied Job Details" onClick={() => openJobModal(job._id)}><i class="fa-solid fa-eye fa-sm"></i></button>
                                                </td>
                                            </tr>
                                        )) : (
                                            <tr>
                                                <td colSpan="8" style={{ textAlign: 'center', color: 'white' }}>
                                                    No Jobs Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <PostApplyDetailsModel isOpen={jobModalOpen} toggle={closeJobModal} job={selectedJob} />
                            </div>
                            <div className="grid-item enquiry pb-5">
                                <h5 className="allTextColor" style={{ marginBottom: '18px', marginTop: '20px' }}>Enquiry</h5>
                                <Table striped hover className="table-dar">
                                    <thead>
                                        <tr className="">
                                            <th>Date</th>
                                            <th>Description</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {enquiryData.length > 0 ? (
                                            enquiryData.map((item, idx) => (
                                                <tr key={idx}>
                                                    {/* Example: Date formatted (can be added if needed) */}
                                                    <td style={{ paddingTop: '7px' }}> {formatDate(item.createdAt)}</td>
                                                    <td style={{ paddingTop: '7px' }}>
                                                        {item.description.split(' ').slice(0, 3).join(' ')}
                                                        {item.description.split(' ').length > 3 ? '...' : ''}
                                                    </td>
                                                    <td className="table-actions">
                                                        {/* <button className="btn-success" size="sm">✎</button> */}
                                                        {/* <button className="btn-danger" size="sm">✕</button> */}
                                                        <button className=" btn-danger" size="sm" onClick={(e) => _deleteEnquiry(item._id)} >✕</button>
                                                        <button className="createbtnColor" size="sm" style={{ margin: '5px' }} title="Enquiry Details" onClick={() => openEnquiryModal(item)}><i class="fa-solid fa-eye fa-sm"></i></button>


                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td colSpan="8" style={{ textAlign: 'center', color: 'white' }}>
                                                    No Enquiries Found
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                                <SenderEnquiryDetailModel isOpen={enquiryModalOpen} toggle={closeEnquiryModal} enquiry={selectedEnquiry} />
                            </div>
                        </div>
                    </div>
                </Container>
            </Container>
            <ToastContainer />
        </Fragment>
    );
};

export default UserDashboard;
