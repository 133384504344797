import React, { memo, useState, useEffect } from 'react';
import { Container, Nav, Navbar, Dropdown } from 'react-bootstrap';
import Logo from "../../../assets/images/frontPageImg/blackLogo.png";
import { Modal, Button } from 'react-bootstrap';
import { Link, useLocation, NavLink } from 'react-router-dom';
import LoginWithOtp from '../Pages/LoginWithOtp';

function Header() {

    const [showModal, setShowModal] = useState(false);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);
    const location = useLocation();
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {  // Adjust this value for when the color should change
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    const getClassName = (path) => location.pathname === path ? 'custom-link active' : 'custom-link';

    return (
        <header id='Home' >
            {/* Navbar */}
            <Navbar collapseOnSelect expand="lg" className={`navbar-overlay header ${isScrolled ? 'scrolled' : ''}`}>
                <Container>
                    <Navbar.Brand as="div">
                        <Link to={`${process.env.PUBLIC_URL}/`}>
                            <img src={Logo} alt="Logo" className="d-lg-block" style={{ maxWidth: "100px", height: "70px" }} />
                        </Link>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ms-auto">
                            <NavLink to={`${process.env.PUBLIC_URL}/`} className='homeLink custom-link'>
                                Home
                            </NavLink>
                            <NavLink to={`${process.env.PUBLIC_URL}/job`} className='homeLink custom-link'>Jobs</NavLink>
                            <NavLink to={`${process.env.PUBLIC_URL}/category-list`} className='homeLink custom-link '>Category</NavLink>
                            <Dropdown>
                                <Dropdown.Toggle className={`homeLink homeheaderDropdown  ${location.pathname.includes('/film-') ? 'active' : ''
                                    }`} variant="link" id="useful-links-dropdown">
                                    Useful Links
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item as={Link} to={`${process.env.PUBLIC_URL}/film-associations`} className={`homeLink ${location.pathname === `${process.env.PUBLIC_URL}/film-associations`
                                        ? 'active'
                                        : ''
                                        }`}>Film Asso. & Org. </Dropdown.Item>
                                    <Dropdown.Item as={Link} to={`${process.env.PUBLIC_URL}/film-festivals`} className={`homeLink ${location.pathname === `${process.env.PUBLIC_URL}/film-festivals`
                                        ? 'active'
                                        : ''
                                        }`}>Film Festivals</Dropdown.Item>

                                    <Dropdown.Item href="#">Film Incentives india</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                            <Link to={`${process.env.PUBLIC_URL}/loginWithOtp`}>
                                <button className="ms-2 btn btnColor"  >
                                    Login/Sign Up
                                </button>
                            </Link>
                            {/* <Link to="#">
                                <button
                                    className="ms-2 btn"
                                    style={{ backgroundColor: "#AA1163", color: "#fff" }}
                                    onClick={handleOpenModal}
                                >
                                    Login/Sign Up
                                </button>
                            </Link> */}
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Modal show={showModal} onHide={handleCloseModal} size="lg" centered>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title className='text-white'>Login / Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body className='loginModelBg'>
                    <LoginWithOtp />
                </Modal.Body>
            </Modal>
        </header >
    );
}

export default memo(Header);
