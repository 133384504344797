import React from 'react';


function SubFooter() {
    return (
        <div class="pb-3 subFooterContainer">
            <div class="container">
                <div className="row">
                    <div className="col-md-6 " style={{ marginTop: '20px' }}>
                        <p className="mb-0 allTextColor">Designed and Developed by Keen Solution</p>
                    </div>
                    <div className="col-md-6 text-md-end allTextColor" style={{ marginTop: '20px' }}>
                        <p className="mb-0">Owned by Picture Company</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default SubFooter;
