import React from 'react';
import Logo from "../../../assets/images/frontPageImg/blackLogo.png";
import smallCircleButton from "../../../assets/images/frontPageImg/smallCircleButton.png";
import { Link } from 'react-router-dom';
import { Nav, } from 'react-bootstrap';
import { Form } from 'reactstrap';


function HomeFooter() {
    return (
        <div class="py-5 subFooterContainer" >
            <div class="container">
                <div class="row">
                    <div class="col-md-4 mb-4">
                        <div class="footer-logo">
                            <Link to={`${process.env.PUBLIC_URL}/`}>
                                <img src={Logo} alt="Logo" />
                            </Link>
                        </div>

                        <div className="social-icons mt-3">
                            <a href="https://www.instagram.com/filmcrewindia_com/profilecard/?igsh=OTV1NTgxNXc2NnB0" className="icon instagram" target="_blank" rel="noopener noreferrer" aria-label="Instagram">
                                <i className="fa fa-instagram"></i>
                            </a>
                            <a href="https://www.facebook.com/profile.php?id=61567197689181&mibextid=LQQJ4d" className="icon facebook" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                                <i className="fa fa-facebook-f"></i>
                            </a>
                            <a href="https://www.linkedin.com/in/filmcrew-india-315a10338/" className="icon linkedin" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                                <i className="fa fa-linkedin"></i>
                            </a>
                            <a href="https://x.com/filmcrewindia" className="icon twitter" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
                                <i className="fa fa-x-twitter"></i>
                            </a>
                        </div>
                        <div class="newsletter mt-4">
                            <h5 class="allTextColor">Join Newsletter</h5>
                            <Form className='userForm'>
                                <div class="input-group">
                                    <input type="email" class="form-control custom-input" placeholder="Enter your email" required />
                                    <button class="btn" type="submit">
                                        <img src={smallCircleButton} alt="Submit" />
                                    </button>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div class="col-md-3 mb-4">
                        <h5 class="allTextColor">Sitemap</h5>
                        <ul className="list-unstyled allTextColor">
                            <li><a href="#About" className="allTextColor">About Us</a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/category-list`} className="allTextColor">Categories</Link></li>
                            {/* <li><a href="#Pricing" className="text-white">Pricing</a></li> */}
                            <li><a href="#Blogs" className="allTextColor">Blogs</a></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/contact-us`} className="allTextColor">Contact Us</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/termsAndConditions`} className="allTextColor">Terms & Conditions</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/privacyPolicy`} className="allTextColor">Privacy Policy</Link></li>
                            <li><Link to={`${process.env.PUBLIC_URL}/disclaimerOfWarranties`} className="allTextColor">Disclaimer Of Warranties</Link></li>
                        </ul>
                    </div>
                    <div class="col-md-4 mb-4" >
                        <h5 class="allTextColor">Contact</h5>
                        <p class="allTextColor"><i class="fas fa-building"></i>FCI Techlabs PVT. LTD.</p>
                        <p class="allTextColor"><i class="fas fa-envelope"></i>info@filmcrewindia.net</p>
                        <p class="allTextColor"><i class="fas fa-map-marker-alt"></i>  Office #1/B,
                            A-Wing,
                            1st floor,
                            Vimal Apartments,
                            Besides Mahindra Cars Showroom,
                            Juhu Lane,
                            Off SV road,
                            Andheri (W).
                            Mumbai - 400061</p>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default HomeFooter;
